import { useEffect, useState } from 'react';
import About from '../components/Home/About';
import Faq from '../components/Home/Faq';
import Header from '../components/Home/Header';
import Rarities from '../components/Home/Rarities';
import Mint from '../components/Home/Mint';
import Roadmap from '../components/Home/Roadmap';
import PhasesRoadmap from '../components/Home/PhasesRoadmap';
import Team from '../components/Home/Team';
import Community from '../components/Home/Community';
import Footer from '../components/Footer';
import '../style/home.scss';

function Home(props) {
 

  return ( 
    <div className="Home">
        <Header/>
        <About/>
        <Mint 
          account={props.account} 
          smartContract={props.smartContract} 
          balance={props.balance}  
          getData={props.getData}  
          getErr={props.getErr}  
        />
        <Roadmap/>
        <PhasesRoadmap/>
        <Rarities/>
        <Team/>
        <Faq/>
        <Community/>
        <Footer/>   
    </div>
  );
}

export default Home;
