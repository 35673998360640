import { useSelector, useDispatch } from 'react-redux';
import { swapFullscreen } from '../../store/conditionHandler/conditionActions';

export default function FullscreenGHORSE(props) {

  const dispatch = useDispatch()
  const condition = useSelector((state) => state.condition);

  const handleFullscreen = () => {
    dispatch(swapFullscreen())
  };
  
  return (
    <div>
    { condition.isFullscreen ? 
    <div onClick={handleFullscreen}  style={{position: "fixed", top: 0, left: 0, display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", zIndex: 99, backgroundColor: "rgba(0, 0, 0, 0.5)"}}>
        <img src={props.img} style={{width: "auto", height: "90%", borderRadius: 40}}/> 
    </div>
    : 
    <></>}
    </div>
  );
  }
  
  