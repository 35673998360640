import '../style/market.scss';
import Header from '../components/Market/Header';
import Content from '../components/Market/Content';

import Footer from '../components/Footer';

function Market() {


 
  return ( 
    <div className="Market">
        <Content/>
        <Footer/>
    </div>
  );
}

export default Market;
