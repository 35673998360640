import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import AvaxLogo from '../assets/avax_logo.png';
import {config} from '../store/config'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect, useState } from 'react';

export default function Items(props) {

  const [item, setItem] = useState("Items")

  useEffect(()=>{
    
    setItem(props.selectClass)
  })

    return (
      <div className="Items">
      {props.selected &&  <CheckCircleIcon style={{position: 'absolute', margin: "5px 10px", color: "green"}}/>}
        <div className='ItemsImg'>
          { props.image ?
            <span>         
              <img  src={`${config.IMAGE_URI}${props.image}.png`} style={{width: "100%", borderBottomRightRadius: 0, borderBottomLeftRadius: 0}}/>
            </span> 
            
          : <span style={{ display: "flex", alignItems: "center"}}><PhotoSizeSelectActualOutlinedIcon/></span>
          }
        </div> 

      </div>
    );
  }
  
  