const initialState = {
  loading: null,
  TOTAL_SUPPLY: 0,
  airdropNumber: 0,
  minted: 0,  
  currentSupply: 0,
  error: false,
  tokenURI: [],
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        TOTAL_SUPPLY: action.payload.TOTAL_SUPPLY,
        airdropNumber: action.payload.airdropNumber,
        minted: action.payload.minted,
        currentSupply: action.payload.currentSupply,
        tokenURI: action.payload.tokenURI,
      
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
