//exporter à header.js la distance.
import React, {useEffect, useRef, useState} from 'react';
import { hideCountdown } from "../../store/conditionHandler/conditionActions";
import { useDispatch } from "react-redux";

function Countdown({saleTime}) {

  const dispatch = useDispatch();
  const [timerDays, setTimerDays] = useState('00');
  const [timerHours, setTimerHours] = useState('00');
  const [timerMinutes, setTimerMinutes] = useState('00');
  const [timerSeconds, setTimerSeconds] = useState('00');

  let interval = useRef();

  const startTimer = () => {
    //const countdownDate = new Date ('October 24 2021 21:26:30').getTime();

      interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = saleTime - now;
      //console.log(distance)

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance <= 0) {
        //stop our timer
        dispatch(hideCountdown());
        clearInterval(interval.current);
      }
      if (distance > 0) {
        //update timer
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }

    }, 1000); 
  };

  function format(s) { return (s < 10) ? '0' + s : s; }


// componentDidMount
  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    }
  }) 

  return (
    <div className="Countdown">
      <h2 style={{letterSpacing: 0}}>{`${format(timerDays)} : ${format(timerHours)} : ${format(timerMinutes)} : ${format(timerSeconds)}`}</h2>
    </div>
  );
}

export default Countdown;
