import SwiperAbout from "./SwiperAbout";

function About() {
  
  return (
    <div className="About" id="About">
      <div>
      <div className="section_about">
      <h1 className="faq_title">ABOUT THE GENTLEHORSEMEN</h1>
        <div className="section_team_aos"   data-aos="fade-up">
        <div className="about_welcome_border">
            <div className="about_welcome">
               <p>The GentleHorsemen is a multi-generational project. The first generation based on a comic book style is a collection
                 of 7,777 randomly generated Horsemen. All our Horsemen are unique. Our goal is to create an exclusive, long-term community
                 that will continually evolve.</p>
               <p>The GentleHorsemen are a restricted circle of gentlemen. What are their purpose ? What are they up to ? Maybe the only
                 way to know it is to be part of this secret community.</p>
                <p></p>
            </div>
          </div>
          <SwiperAbout/>
        </div>
      </div>
      </div>
    </div>
  );
}

export default About;
