import { useState, useEffect } from 'react';
import Items from './Items';

import GridOnIcon from '@mui/icons-material/GridOn';
import WindowIcon from '@mui/icons-material/Window';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ReplayIcon from '@mui/icons-material/Replay';
import SearchIcon from '@mui/icons-material/Search';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AvaxLogo from '../../assets/avax_logo.png';
import ProfileBanner from '../../assets/profile_banner.png';
import ProfileMobileBanner from '../../assets/profile_banner_mobile.png';
import { swapFullscreen, updateURI } from '../../store/conditionHandler/conditionActions';
import { fetchData } from '../../store/data/dataActions';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { config } from '../../store/config';
import FullscreenGHORSE from '../Dialogbox/FullscreenGHORSE';




export default function Profil(props) {
  
  const dispatch = useDispatch();
  const condition = useSelector((state) => state.condition);
  const data = useSelector((state) => state.data);

  const [item, setItem] = useState(0)
  const [itemFilter, setItemFilter] = useState(false)

  const [isformatBig, setIsformatBig] = useState(false)
  const [format, setformat] = useState([{ xs: 2, sm: 4, md: 12, xl: 10 },{ xs: 2, sm: 2, md: 3, xl: 2 } ])

  const [nfts, setNfts] = useState([])
  const [allnfts, setAllNfts] = useState([])
  const [NFTloaded, setNFTLoaded] = useState(false)
  const [allNFTloaded, setAllNFTLoaded] = useState(false)
  const [marketReward, setMarketReward] = useState(0)
  const [postMintReward, setpostMintReward] = useState(0)
  const [allReward, setallReward] = useState(0)
  const [claimingReward, setClaimingReward] = useState(false)
  const [inputValue, setInputValue] = useState("");
  const [filtredValue, setfiltredvalue] = useState([]);
  const [imgValue, setimgValue] = useState(null);
  const [setURI, setsetURI] = useState([]);

  const onChangeHandler = event => {
    setInputValue(event.target.value);
    if(allnfts !== []){
      let truc = allnfts.filter(nft => nft.includes(inputValue))
      setfiltredvalue(truc)
    }
  };

  const handleFullscreen  = async (i) => {
    let truc = parseInt(data.tokenURI[i])
    dispatch(swapFullscreen(truc))
  };
  //api declarations

  const SwitchMainContent = (v) => {setItem(v)}
  const SwitchFormat = () => {
    setIsformatBig(!isformatBig)
    if(!isformatBig){
      setformat([{ xs: 4, sm: 6, md: 10, xl: 12 },{ xs: 2, sm: 2, md: 2, xl: 2 }])
    }
    else{
      setformat([{ xs: 2, sm: 4, md: 12, xl: 10 },{ xs: 2, sm: 2, md: 3, xl: 2 } ])
    }
  }
  const SwitchItemFilter = () => {setItemFilter(!itemFilter)}


  const trimData = (data, l) => {
    if(data !== (null || undefined)){
    const myData = props.blockchain.web3.utils.fromWei(
      data,
      "ether"
    );
    return myData;
    }
  }



  const claimPostMReward = () => 
  {
    if(postMintReward !== 0){
      try{
      setClaimingReward(true);
      props.blockchain.smartContract.methods
        .claimRewards()
        .send({
          from: props.blockchain.account,
        })
        .once("error", (err) => {
          console.log(err);
          setClaimingReward(false);
        })
        .then(() => {
          setClaimingReward(false);
        });
      }
      catch(err){
        console.log(err)
      }
    }
  } 


  const refreshData = () => {
    setNfts([]);
    setAllNfts([]);
    dispatch(fetchData())
  }
  useEffect(() => {
   setNfts([]);
   setAllNfts([]);
  }, [props.blockchain.account])


return (
    <div className="Profil">
        <FullscreenGHORSE img={`${config.IMAGE_URI}${condition.imgID}.png`}/> 
        <div className="ProfilBanner">
          <img alt="market banner" src={ProfileBanner} /> 
        </div>
        <div className="ProfilMobileBanner">
          <img alt="market banner" src={ProfileMobileBanner} /> 
        </div>

        <div className="ProfilHeader">
          <span onClick={() => item == 0 ? '' : SwitchMainContent(0)} className={item == 0 && "contentFetched"} >
            <GridOnIcon style={{marginRight: '10px'}}/>GHORSE
          </span>
          <span onClick={() => item == 3 ? ''  : SwitchMainContent(3)} className={item == 3 && "contentFetched"} >
          <EmojiEventsIcon style={{marginRight: '10px'}}/>Rewards
          </span>
        </div>
        <div className="ProfilHeaderMobile">
            <span onClick={() => item == 0 ? ''  : SwitchMainContent(0)} className={item == 0 && "contentFetched"} >
              <GridOnIcon style={{marginRight: '10px'}}/> 
              <div>
                  {item == 0 && <text>GHORSE</text> }
              </div>
            </span>
            <span onClick={() => item == 1 ? ''  : SwitchMainContent(1)} className={item == 1 && "contentFetched"} >
              <AddShoppingCartIcon style={{marginRight: '10px'}}/>
              <div>
                    {item == 1 && <text>Sales activity</text> }
              </div> 
            </span>
            <span onClick={() => item == 2 ? ''  : SwitchMainContent(2)} className={item == 2 && "contentFetched"} >
              <LocalOfferIcon style={{marginRight: '10px'}}/> 
              <div>
                    {item == 2 && <text>Offers</text> }
              </div>
            </span>
            <span onClick={() => item == 3 ? ''  : SwitchMainContent(3)} className={item == 3 && "contentFetched"} >
              <EmojiEventsIcon style={{marginRight: '10px'}}/> 
              <div>
                    {item == 3 && <text>Rewards</text> }
              </div>
            </span>      
        </div>
        { item == 0 && 
        <div className="ProfilCtn">
             <div style={{width:'100%', padding: '30px', boxSizing: "border-box"}}>
            <div className="MrkContentItemsHead">
              <div className="MrkContentItemsSubHead-1" >
                <div  onClick={refreshData} className="MrkContentItemsHead-reload"><ReplayIcon/></div>
                {/* <span className="format-1"><div><SearchIcon/></div><input  onChange={onChangeHandler} placeholder="Search"/></span> */}
                <span className="format-1"><div><SearchIcon/></div><input  onChange={onChangeHandler} placeholder="Search"/></span>
                <span style={{cursor: "pointer"}}>
                <div onClick={() => itemFilter && SwitchItemFilter()} style={{borderRadius: "4px 0 0 4px"}} className={ !itemFilter ? "filterActive" : "filter"}><div style={{padding: "12px 22px"}}><p>All</p></div></div>
                <div onClick={() => !itemFilter && SwitchItemFilter()} style={{borderRadius: "4px 0 0 4px"}} className={ itemFilter ? "filterActive" : "filter"}><div style={{padding: "12px 22px"}}><p>On sale</p></div></div>
                </span>
              </div>
              <div className="MrkContentItemsSubHead-2">
              <div className="MrkContentItemsHead-format">
                <div onClick={() => isformatBig && SwitchFormat()} style={{borderRadius: "4px 0 0 4px"}} className={ !isformatBig && "formatActive"}><WindowIcon/></div>
                <div onClick={() => !isformatBig && SwitchFormat()} style={{borderRadius: "0 4px 4px 0"}} className={ isformatBig && "formatActive"}><GridOnIcon/></div>
              </div>
              </div>
            </div>
            { !itemFilter && 
              <div className="ProfilItems">
              {
                (allnfts == ([] ||  [undefined]) && data.tokenURI !== [])                ? 
                  <h2>No items to display</h2>
                  :
                  <Grid container columnSpacing={{ xs: 2, md: 3 }} columns={format[0]} className="MrkContentItemsCnt">
                    { data.loading == false ? data.tokenURI.map( (nft,i) => (
                   <Grid  onClick={() => handleFullscreen(i)} item xs={format[1].xs} sm={format[1].sm} md={format[1].md} xl={format[1].xl} key={parseInt(nft)}>
                        <Items  name={`#${parseInt(nft)}`} image={parseInt(data.tokenURI[i])}/>
                    </Grid>
                   
                    )) : <></> 
                    }
                </Grid>
                } 
              </div>
            }          
          </div>
        </div>
        }
       
         { item == 3 &&  (
        <div className="ProfilRewards">
 
          <div style={{marginTop: 70}}>
   
                <div style={{padding: "10px 0"}}>
                  <span className="ProfilRewards-Ctn">
                    <p>Post-mint Reward</p>
                    <div>
                      { postMintReward == 0 ? <p style={{fontWeight: 700}}>--</p> : <p style={{fontWeight: 700}}>{trimData(postMintReward)}</p>}
                      <img alt="market banner" src={AvaxLogo} style={{width:"15px", height:"15px", margin:"7px 15px 7px 8px"}}/> 
                      <button disabled={ claimingReward ? 1 : 0} onClick={(e) => {
                          e.preventDefault();
                          claimPostMReward()}}>
                          CLAIM
                      </button>                    
                    </div>
                  </span>  

                  <span className="ProfilRewards-Ctn">
                    <p>Market Reward</p>
                    <div>
                    { marketReward == 0 ? <p style={{fontWeight: 700}}>--</p> : <p style={{fontWeight: 700}}>{trimData(marketReward)}</p>}
                      <img alt="market banner" src={AvaxLogo} style={{width:"15px", height:"15px", margin:"7px 15px 7px 8px"}}/> 
                      <button disabled={ claimingReward ? 1 : 0} onClick={(e) => {
                          e.preventDefault();
                          }}>
                          CLAIM
                      </button>
                    </div>
                  </span>  
                </div>
                <div>
                <span className="ProfilRewards-Foot">
                    <p>TOTAL</p>
                    <div style={{display: "flex", alignItems: "center"}}>
                    { allReward == 0 ? <p style={{fontWeight: 700}}>--</p> : <p style={{fontWeight: 700}}>{trimData(postMintReward)}</p>}
                      <img alt="market banner" src={AvaxLogo} style={{width:"15px", height:"15px", margin:"7px 15px 7px 8px"}}/> 
                      <button disabled={ claimingReward ? 1 : 0} onClick={(e) => {
                          e.preventDefault();
                          claimPostMReward()}}>
                          CLAIM ALL</button>
                    </div>
                  </span>  
                </div>
              </div>
        </div>
        )}
    </div>
  );
}



export const StringContent = (i, metadata) => {
  if(i){  
    return(
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 4, md: 12, xl: 12 }} className="MrkContentItemsCnt">
        <Grid item xs={2} sm={2} md={4} xl={3} key={metadata[0].edition}>
        <a href={`/asset/${metadata[0].edition}`}>
          <Items name={`#${metadata[0].edition}`} image={metadata[0].edition} />
        </a>
      </Grid>
  </Grid>)
  }
  else{
    return <></>
  }
};