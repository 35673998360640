import {forwardRef} from 'react'
import {  useDispatch, useSelector } from 'react-redux';
import { swapErrorDisplay } from '../../store/conditionHandler/conditionActions';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { config } from '../../store/config';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function ErrorHandler(props) {

  const condition = useSelector((state) => state.condition);
  const dispatch = useDispatch();


  const switchNetwork = async () => {
    const { ethereum } = window; //window représ une fenêtre contenant un document DOM, chaque onglet contient son propre objet window
      try {
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: config.NETWORK.CHAINID }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{ chainId: config.NETWORK.CHAINID, chainName: config.NETWORK.NAME, rpcUrls: [config.NETWORK.RPC] }],
            });
          } catch (addError) {
            // handle "add" error
          }
        }
        // handle other "switch" errors
      }
    }

  const handleClose = () => {
    dispatch(swapErrorDisplay())
  };

  return (
    <Dialog
    open={condition.errorDisplay}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
    sx={{
      '& .MuiPaper-root': {
        background: 'none',
      }
      }}
    >
    <div  className='BuySell'>          
        <div className='BuySell-head'><h3>Switch Network to the Avalanche Mainnet</h3></div>
        <div className='BuySell-content' style={{padding: "38px 35px 38px 55px"}}>
            <p>In order to use features involving blockchain communication, please switch to Avalanche network within your MetaMask wallet.</p>
        </div>
        <span className='BuySell-footer' >                  
            <button onClick={handleClose} className="Asset-button-2" style={{width: "50%", marginRight: 10}}>Cancel</button>
            <button className="Asset-button" 
            style={{width: "50%"}}
            disabled={props.claimingReward ? 1 : 0} 
            onClick={(e) => {
            e.preventDefault();
            switchNetwork();
            }}>Switch network</button>      
        </span>
    </div>
    </Dialog>
    );
}

export default ErrorHandler;
