

function Header() {

  return (
    <div className="Header">
      <div className="section_header">
        <a href="#Mint" className="clothing_rack">
        </a>
        <div className="header_content">
            <a href="#About">
                <div className="scroll-down"></div>
            </a>
        </div>
      </div> 
    </div>
  );
}

export default Header;
