export const fetchCountdown = () => {
  return {
    type: "COUNTDOWN_FETCH",
  };
};

export const hideCountdown = () => {
  return {
    type: "COUNTDOWN_HIDE",
  };
};

export const fetchPopover = () => {
  return {
    type: "POPOVER_FETCH",
  };
};

export const hidePopover = () => {
  return {
    type: "POPOVER_HIDE",
  };
};

export const closeDialog = () => {
  return {
    type: "DIALOG_CLOSE",
  };
};

export const openDialog = (payload) => {
  return {
    type: "DIALOG_OPEN",
    payload: payload,
  };
};

export const closeBuySell = () => {
  return {
    type: "BUYSELL_CLOSE",
  };
};

export const openBuySell = (payload) => {
  return {
    type: "BUYSELL_OPEN", 
    payload: payload,
  };
};

export const swapFullscreen = (payload) => {
  return {
    type: "FULLSCREEN_SWAP",
    payload: payload,

  };
};

export const swapErrorDisplay = () => {
  return {
    type: "ERRORMSG_SWAP",
  };
};


export const updateURI = (payload) => {
  return {
    type: "UPDATE_URI",
    payload: payload,
  };
};
