import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useSelector, useDispatch } from 'react-redux';
import { closeDialog } from '../../store/conditionHandler/conditionActions';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NftDetails() {

  const dispatch = useDispatch()
  const condition = useSelector((state) => state.condition);

  const handleClose = () => {
    dispatch(closeDialog())
  };


    return (
      <Dialog
          className='Dialog'
          open={condition.dialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
        {/* <div className="Asset-mainctn">
          <div className="Asset-l">
              <div className="Asset-l1">
                { (metadata !== "" && metadata !== undefined) ?
                  <span className='Asset-image' onClick={handleFullscreen}>         
                    <img  src={`${config.IMAGE_URI}${metadata.edition}.png`} style={{width: "100%"}}/> 
                  </span> 
                : <PhotoSizeSelectActualOutlinedIcon/> 
                }
              </div>
          </div>
          <div className="Asset-r">
            <div className="Asset-r1">
            { metadata !== undefined && <h2>{metadata.name} </h2>}
              { nfts[0] !== undefined && 
              <span>
                <h4>Owned by -</h4>
                <a style={{marginLeft: 7, fontSize: "14px", marginTop: "3.1px", color:"rgb(32, 129, 226)"}} href={config.SCAN_LINK+nfts[0].owner}>{truncate(nfts[0].owner, 6)}</a>
              </span> }       
            </div>
            <div className="Asset-r2">
              <div className={isDivOpened2 ? "subAsset-l2": "subAsset-l2-bordernone"} onClick={() =>{handleHideTab(setIsDivOpened2, isDivOpened2)}}>
                <span><FormatAlignCenterIcon style={{color: "white"}}/><h4  style={{marginLeft: '8px'}}>Attributes</h4></span>
              </div>
              { isDivOpened2 &&
              <Grid container spacing={{ xs: 2 }} columns={{ xs: 12}} style={{padding:"0 26px 26px 50px", marginTop: 16, display: "flex"}} id="#attributes">
                { (metadata !== '' && metadata !== undefined) ? metadata.attributes.map((attr, i) => (
                  <Grid key={i} item xs={4}  style={{padding: 0}}>
                    <div className="subAsset-r2"> 
                      <h6>{attr.trait_type}</h6>
                      <h4>{attr.value}</h4>
                    </div>
                  </Grid>
                )) : <></>}
              </Grid>
              }
            </div>
            <div className="Asset-r3">
             
            { nfts[0] !== undefined &&
              <span className='Asset-price'>
                <h2>{nfts[0].price / 1000000000000000000}</h2>
                <img alt="avax logo" src={AvaxLogo} style={{width:"19px", height:"19px", margin:"7px 5px 7px 10px"}}/>
                <p>AVAX</p>
              </span>
            }
            { nfts[0] !== undefined &&
            <div>
              { nfts[0].owner.toLowerCase() !== blockchain.account.toLowerCase()  &&  
              <>
                <button className="Asset-button" 
                  style={{width: "100%"}}
                  disabled={claimingReward ? 1 : 0} 
                  onClick={(e) => {
                  e.preventDefault();
                  handleClickOpen(0);
                  }}> Buy now </button> 
              </>
              }
              </div>
              }
            </div>
          </div>
        </div> */}
      </Dialog>
    );
  }
  
  