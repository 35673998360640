
function PhasesRoadmap() {
  return (
    <div className="PhasesRoadmap">
      <div className="section_phases_roadmap">
        <div className="section_roadmap_aos">

            <h1 className="roadmap_title">PHASES</h1>

            <div className="roadmap_line_phase">
              <div className="roadmap_card_phase" data-aos="fade-up">
                <div className="roadmap_title_phase phase_1">
                  <span>Phase</span>
                  <h1 className="">01</h1>
                </div>
                <div className="roadmap_container_phase  phase_1_container">
                  <h2>The Beginning</h2>
                  <p className="roadmap_text_phase">
                  Launch of the 7,777 GentleHorsemen public sale. You can now become a true gentleman. This is the beginning of a great adventure !
                  </p>
                </div>
              </div>
              <div className="roadmap_card_phase" data-aos="fade-up">
                <div className="roadmap_title_phase">
                    <span>Phase</span>
                    <h1 className="">02</h1>
                </div>
                <div className="roadmap_container_phase">
                  <h2>The Succession</h2>
                  <p className="roadmap_text_phase">
                    Launch of the rascals foals. They are the second fresh new generation! A new graphic style will be realized for this collection of
                    3,333 Rascal Foals. They will have the particularity to be able to choose their faction ! They can grow up to be a big and brave
                    rascal horseman or a graceful Rascal Mare ! (Check the lore to learn more about the Spy mare).
                  </p>
                </div>
              </div>
              <div className="roadmap_card_phase" data-aos="fade-up">
                <div className="roadmap_title_phase">
                    <span>Phase</span>
                    <h1 className="">03</h1>
                </div>
                <div className="roadmap_container_phase">
                  <h2>The Universe</h2>
                  <p className="roadmap_text_phase">
                  Development and creation of future generations, their stories and the possible interactions between them and previous generations
                  </p>
                </div>
              </div>
              <div className="roadmap_card_phase" data-aos="fade-up">
                <div className="roadmap_title_phase">
                  <span>Phase</span>
                  <h1 className="">04</h1>
                </div>
                <div className="roadmap_container_phase">
                  <h2>The Future</h2>
                  <p className="roadmap_text_phase">
                  Introduction of the private gentlemen's club. This is a dedicated very secret club where you will be able to chat and play games
                  (maybe black jack 😏) with other distinguished members. We will communicate more about it in the near future.
                  </p>
                </div>
              </div>
            </div>

        </div>
      </div>
    </div>
  );
}

export default PhasesRoadmap;
