import { Link } from 'react-router-dom'
import Pyramid from '../../assets/Pyramid_Rarities.png';

function Rarities() {
  return (
    <div className="Rarities">
          <div className="section_rarities">
      <div className="section_rarities_aos">
      <h1 className="rarities_title">RARITIES</h1>
      <div className="rarities_container"  data-aos="fade-up">
          <div className="rarities_left">
              <p>Some horsemen and assets will be more difficult to get than others. The rarity list ranks them by category from "Common"
                to "Exotic". Exotic being the rarest category. There are a total of 148 assets grouping 7 assets categories, each asset has
                its own rarity value which will be displayed as a percentage on the official minting date.</p>        
  
          <div className="rarities_liste">
              <div className="rarities_common">
                  <h2>COMMON</h2>
                  <span>50%</span>
              </div>
              <div className="rarities_uncommon">
                  <h2>UNCOMMON</h2>
                  <span>30%</span>
              </div>
              <div className="rarities_rare">
                  <h2>RARE</h2>
                  <span>15%</span>
              </div>
              <div className="rarities_epic">
                  <h2>EPIC</h2>
                  <span>4,8%</span>
              </div>
              <div className="rarities_exotic">
                  <h2>EXOTIC</h2>
                  <span>0,2%</span>
              </div>
          </div>
          </div>
          <div className="rarities_right">
            <img className="pyramid_img" alt="img rarities" src={Pyramid}/>
          </div>
        </div>

      </div>
    </div>  
    </div>
  );
}

export default Rarities;
