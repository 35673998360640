import HorsemanDiscord from '../../assets/Join_Discord.webp';
import HorsemanTelegram from '../../assets/Join_Telegram.webp';

function Community() {
    return (
        <div className="community">
            <div className="section_community">
                <div className="community_left" data-aos="fade-up"> 
                    <img alt="logo horsemenclub" src={HorsemanDiscord}/>  
                </div>
                <div className="community_center" data-aos="fade-up">
                    <h1>Join the organization</h1>
                    <p>Ladies and Gentlemen. Join the Discord and the Telegram to participate to exclusive events/giveaways and get all the news as soon as possible. Put on your best suit and join the GentleHorsemen's club ! 
                        </p>
                        <div className="community_link">
                            <a href="https://discord.gg/99ekYG7Cqk" target="_blank" rel="noreferrer">
                                <button className="button_community button_discord"><span>Join our Discord</span></button>
                            </a>
                            <a href="https://t.me/gentlehorsemen" target="_blank" rel="noreferrer">
                                <button className="button_community button_telegram"><span>Join our Telegram</span></button>
                            </a>
                        </div>
                </div>
                <div className="community_right" data-aos="fade-up">
                    <img alt="logo horsemenclub" src={HorsemanTelegram}/> 
                </div>
            </div>
        </div>
    )
}

export default Community;
