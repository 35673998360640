import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import AvaxLogo from '../../assets/avax_logo.png';
import {config} from '../../store/config'

export default function Items(props) {

 

    return (
      <div className='Items'>
        <div className='ItemsImg'>
          { props.image ?
            <span>         
              <img  src={`${config.IMAGE_URI}${props.image}.png`} style={{width: "100%", borderBottomRightRadius: 0, borderBottomLeftRadius: 0}}/>
            </span> 
            
          : <span style={{height: "310px", display: "flex", alignItems: "center"}}><PhotoSizeSelectActualOutlinedIcon/></span>
          }
        </div> 
        <div className='ItemsContent'>
          <div>
          {props.name && 
            <div style={{marginTop: 10}}>
              <p>GHORSE</p> 
              <b>{props.name}</b>
            </div>
          }
          </div>
          <div style={{display: "flex", flexDirection:"column", alignItems: "flex-end"}}>
          {props.price ?
            <>
              <p>Price</p> 
              <span><img alt="market banner" src={AvaxLogo} style={{width:"15px", height:"15px", margin:"7px 5px"}}/><h4>{props.price}</h4></span> 
            </>
          :
          <></>
          }
          </div>
        </div> 
        <div className="ItemsFooter">
        </div>
      </div>
    );
  }