export const config = {
  CONTRACT_ADDRESS: "0xdB5675cDA5b67f0a048a61011264A1B527137fC0",
  SCAN_LINK: "https://snowtrace.io/address/",
  NETWORK: {
    NAME: "Avalanche Network",
    SYMBOL: "AVAX",
    ID: 1,
    CHAINID: "0xa86a",
    RPC: "https://api.avax.network/ext/bc/C/rpc"
  },
  NFT_NAME: "Horseboys",
  IMAGE_URI:  "https://ipfs.io/ipfs/QmZic6XWBF6xgu3Ba2mMcfSQ3SUvdTm8VWFNetmCwVDJgP/",
  SYMBOL: "HORSE",
  MAX_SUPPLY: 7777,
  MARKETPLACE: "KALAO",
  MARKETPLACE_LINK: "https://opensea.io/collection/horseboys"  
};


export default {
  config,
}