import SwiperCore, { Autoplay, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.scss";

import Asset1 from '../../assets/Asset1.png';
import Asset2 from '../../assets/Asset2.png';
import Asset3 from '../../assets/Asset3.png';
import Asset4 from '../../assets/Asset4.webp';
import Asset5 from '../../assets/Asset5.webp';
import Asset6 from '../../assets/Asset6.webp';
import Asset7 from '../../assets/Asset7.webp';
import Asset8 from '../../assets/Asset8.webp';
import Asset9 from '../../assets/Asset9.webp';
import Asset10 from '../../assets/Asset10.webp';
import Asset11 from '../../assets/Asset11.png';

SwiperCore.use([Autoplay, Thumbs]);

export default () => {
    return (
      <div className="swiper">
          <Swiper className="swiper_about"
          slidesPerView={4}
          spaceBetween={30}
          loop="true"
          autoplay={{
              "delay": 2500,
              "disableOnInteraction": false
            }}
        >
          <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset1}/></SwiperSlide>
          <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset3}/></SwiperSlide>
          <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset2}/></SwiperSlide>
          <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset4}/></SwiperSlide>
          <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset5}/></SwiperSlide>
          <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset6}/></SwiperSlide>
          <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset7}/></SwiperSlide>
          <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset9}/></SwiperSlide>
          <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset8}/></SwiperSlide>
          <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset10}/></SwiperSlide>
          <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset11}/></SwiperSlide>
        </Swiper> 

        <Swiper className="swiper_about_responsive"
        slidesPerView={2}
        spaceBetween={30}
        loop="true"
        autoplay={{
            "delay": 2500,
            "disableOnInteraction": false
          }}
        >
        <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset1}/></SwiperSlide>
        <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset3}/></SwiperSlide>
        <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset2}/></SwiperSlide>
        <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset4}/></SwiperSlide>
        <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset5}/></SwiperSlide>
        <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset6}/></SwiperSlide>
        <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset7}/></SwiperSlide>
        <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset9}/></SwiperSlide>
        <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset8}/></SwiperSlide>
        <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset10}/></SwiperSlide>
        <SwiperSlide className="swiper_slide_about"><img className="swiper_img" alt="img team" src={Asset11}/></SwiperSlide>
        </Swiper> 
      </div>
    );
  };