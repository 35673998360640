import { BrowserRouter, Switch, Route} from "react-router-dom";
import React, {useState, useEffect} from "react";
import './App.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Home from './views/Home';
import NotFound from './views/NotFound';
import Horsehoe from './assets/Horsehoe.gif';
import Navbar from "./components/Navbar";
import MyGHORSE from "./views/MyGHORSE";
import Market from "./views/Market";
import TermsConditions from "./views/TermsConditions";
import Tokenomics from "./views/Tokenomics";

//web3 import
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./store/blockchain/blockchainActions";
import { fetchData } from "./store/data/dataActions";
import NftDetails from "./components/Dialogbox/NftDetails";
import ErrorHandler from "./components/Dialogbox/ErrorHandler";


const truncate = (input, len) =>
input.length > len ?`${input.substring(0, len)} AVAX` : input;

function App() {
  const [loading, setLoading] = useState(false);
  //web3 variable
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [balance, setBalance] = useState('');



  //SPLASH SCREEN PART
  useEffect(() => {
    setLoading(true); 
    setTimeout(() => {
      setLoading(false);
    }, 500); 
  }, []);

  AOS.init();

  //WEB3 methods
  
  //Renvoie le cost, saleTime et totalSupply à chaque fois qu'il est executé, disable le button connect, reload la page s'il ne trouve pas de compte
  const getData = () => {
    if (blockchain.account !== "" && blockchain.account !== null  && blockchain.smartContract !== null) 
    {
      dispatch(fetchData(blockchain.account));
      getBalance(); 
      if (blockchain.account == null) 
      {
        window.location.reload();
      }
    } 
  };

  const getBalance = async () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) 
    {
      const myBalance = blockchain.web3.utils.fromWei(
        await blockchain.web3.eth.getBalance(blockchain.account),
        "ether"
      );
      const trimBalance = truncate(`${myBalance}`, 5)
      setBalance(trimBalance);
    }
  }

  const getErr = () => {
    console.log(blockchain.errorMsg);
    return blockchain.errorMsg;
  };
  
  useEffect(() => {
    getErr(); 
  }, [blockchain.errorMsg]);

  useEffect(() => {
    getData(); 
  }, [blockchain.account]);

  useEffect(() => {
    dispatch(connect());
  }, []);


  return (

    <div className="App">
          {loading ? (
            <div className="loader_screen">
              <img className="loader_logo_img" alt="Logo Horsemen" src={Horsehoe}/>
            </div>
          ) : (
          <div>
            <BrowserRouter>
              <Navbar connected={blockchain.connected} account={blockchain.account} smartContract={blockchain.smartContract} connect={connect()}/>
              <NftDetails/>
              <ErrorHandler/>
              <Switch>
                <Route exact path="/">
                  <Home       
                    account={blockchain.account} 
                    smartContract={blockchain.smartContract}
                    balance={balance}  
                    getData={getData}  
                    getErr={getErr}  
                  />
                </Route>
                <Route exact path="/myghorse">
                  <MyGHORSE       
                    account={blockchain.account} 
                    blockchain={blockchain}           
                  />
                </Route>
                <Route path='/stake' exact component={Market}/>
                <Route path='/terms' exact component={TermsConditions}/>
                <Route path='/whitepaper' exact component={Tokenomics}/>
                <Route component={NotFound}/>
              </Switch>
            </BrowserRouter>
          </div>
          )
        }
     </div>

  );
}

export default App;
