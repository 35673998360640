import '../style/navbar.scss';
import React, {useEffect, useState} from "react";
import Logo from '../assets/NewLogo.png';
import { Link } from 'react-router-dom';
/*import Opensea from '../assets/Opensea.webp';*/
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

const truncate = (input, len) => input.length > len ? `${input.substring(0, len)}...` : input;


function Navbar(props) {

  //path-detect variable
  const location = useLocation()
  const [isHome, setIsHome] = useState(false)
  //web3 variable
  const dispatch = useDispatch();
  const [buttonTxt, setButtonTxt] = useState('Connect Wallet')
  const [isDisabled, setIsDisabled] = useState(false)
  const [popover, setPopover] = useState(true)

  const walletAddress = truncate(`${props.account}`, 6)

  const detectPath = () => {
    if(location.pathname === '/'){
      setIsHome(true)
    }
    else{
      if(isHome){
        setIsHome(false)
      }
    }
  }
  
  //web3 methods
  const getData = () => {
    // const { ethereum } = window; 
    // const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if ( props.account !== "" &&  props.account !== null  &&  props.smartContract !== null) 
    {
      setButtonTxt(walletAddress)
      setIsDisabled(true)
      console.log('button disabled') 

      if (props.account == null) 
      {
        setIsDisabled(false)
        console.log(isDisabled) 
      }
    }
    // if ( props.account == null && metamaskIsInstalled ) 
    // {
    //   setButtonTxt('Switch to Avalanche network')
    //   setIsDisabled(false)
    //   console.log(isDisabled) 
    // }
  }

  useEffect(() => {
    detectPath()
  }, [location])

  useEffect(() => {
    getData(); 
  }, [props.account]);


  return (
    <div className={ isHome ? "Navbar" : "NavbarPlain"}>
      <Link to="/" className={ isHome ? "title_logo" : "title_logo_min"}>
        <img alt="logo horsemenclub" src={Logo} />
      </Link>

        <div className="navbar_right_part">
          <div className="navbar_menu">
            <span className="navbar_item">
              <Link to="/">
                Home
              </Link>
            </span>
            <span className="navbar_item">
              <Link to="/whitepaper">
                Whitepaper
              </Link>
            </span>
            <span className="navbar_item">
              <Link to="/myghorse">
                My GHORSE
              </Link>
            </span>
            <span className="navbar_item">
              <a href="/stake">
                Staking
              </a>
            </span>
          </div>

          <div className="social_nav">
            <button className="button_connect" disabled={isDisabled} onClick={(e) => {
                e.preventDefault();
                dispatch(props.connect);
              }}>
              <span>{buttonTxt}</span>
            </button>
            <h2>{props.errorMsg}</h2>
          </div>
      </div>
      
      {/* Here is the mobile part */}
      <div className="burger_menu">
        <nav role="navigation">
          <div id="menuToggle">
            <input type="checkbox" />

            <span></span>
            <span></span>
            <span></span>
            
            <ul id="menu">
              <img className="burger_logo" alt="logo horsemenclub" src={Logo}/>
              <li><a href="/">
                Home
              </a></li>
              <li><a href="/whitepaper">
                Whitepaper
              </a></li>
              <li><a href="/myghorse">
                My GHORSE
              </a></li>
              <li><a href="/staking">
                Staking
              </a></li>
              <button className="button_connect_mobile" disabled={isDisabled} onClick={(e) => {
                e.preventDefault();
                dispatch(props.connect);
              }}>
              <span class="t12">{buttonTxt}</span>
            </button>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
