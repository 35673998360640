import Mannequin from '../../assets/mannequin_light.png';
import MintGif from '../../assets/Mint_gif.gif';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../store/data/dataActions';
import { fetchCountdown } from "../../store/conditionHandler/conditionActions";
import { swapErrorDisplay } from '../../store/conditionHandler/conditionActions';
import { config } from '../../store/config';
import GHORSEHead from '../../assets/horse-face.gif';
import axios from 'axios';

import Countdown from './Countdown';

function Mint(props) {

  const dispatch = useDispatch();
  const condition = useSelector((state) => state.condition);
  const data = useSelector((state) => state.data);

  const [claimingNft, setClaimingNft] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const countdownDate = 1642701600000;


  const [feedback, setFeedback] = useState('');

  const incrMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };
  const decrMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };


  const callCountdown = () => {
    const now = new Date().getTime();
    const distance = countdownDate - now;
    if(distance > 0 && condition.countdownVisible == null){
      dispatch((fetchCountdown()));
    }
  }
  
  // **************************
  // ** WEB3 UTILITY METHODS **
  // **************************  

  // ********************
  // ** CLAIM TOKEN **
  // ********************
  const mint = (n) => {

    console.log(data.minted)
    props.smartContract.methods
    .getNextBatchPrice(n)
    .call()
    .then(function(result){
       console.log(`Price for ${n} token is ${result} wei`)
       claim(n, result)
    })    
  };

  const claim = (n, nb) => 
  {
    let gasLimit = config.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * n);

    console.log("Gas limit: ", totalGasLimit);
    setClaimingNft(true);
    props.smartContract.methods
      .claim(n)
      .send({
        //gasLimit: String(totalGasLimit),
        to: config.CONTRACT_ADDRESS,
        from: props.account,
        value: nb,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("something went wrong.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        for(let i = n; i > 0 ; i--){
          let ghorseID = parseInt(data.minted) + parseInt(i) - 1;
          console.log(ghorseID)
          let marketUpdate = { 
          "_id": ghorseID.toString(),
          "buyer": props.account
          };
          axios.put(`${config.TOKEN_URI}market/mint`, marketUpdate)
          .then(response => console.log(response))
          .catch(error => {
            console.error('API put occured error!', error);
          });
        }
 
        setFeedback(
          `${config.NFT_NAME} minted successfully !`
        );
        setClaimingNft(false);
        dispatch(fetchData(props.account));
      });
  };

  // ******************
  // ** UPDATING DOM **
  // ******************

  useEffect(() => {
    callCountdown();
  }, []);

  return (
    <div className="Mint" id="Mint">
      <div className="section_mint">
        <div className="mint_left">
          <img className="mannequin_light" alt="img mint" src={Mannequin}/>
          <img className="mint_gif" alt="img mint" src={MintGif}/>
        </div>
        <div className="mint_right">
          <div className="mint_container"  data-aos="fade-up">
            <p>Mint here and join the secret community!</p>
            { condition.countdownVisible ? 
              <div>
                <Countdown saleTime={countdownDate}/>
              </div>
            :
              <div>
                {Number(data.totalSupply) >= config.MAX_SUPPLY ? 
                  <h2>The sale has ended.</h2>
                :(
                  <>              
                    <div>
                      <h2 style={{fontSize: "42px"}}> {data.currentSupply} / {config.MAX_SUPPLY}</h2>
                      { /* mint 1  NFT */}
                      { props.account == "" || props.smartContract == null ? (
                        <button className="button_mint"  
                          onClick={(e) => {
                          e.preventDefault();                          
                          dispatch(swapErrorDisplay())
                        }} 
                        style={{marginTop: 40}}>
                        <span className="mint_button_text">MINT</span></button> 
                        ) 
                      :(
                        <div  style={{marginTop: 40}}>
                          <div> 
                            <div className="mintCount">
                              <button onClick={decrMintAmount}>-</button>
                              <span style={{color: 'white', margin: 20}}><p>{mintAmount}</p></span>
                              <button onClick={incrMintAmount}>+</button>
                            </div>               
                          </div>
                          <button className="button_mint" 
                            disabled={claimingNft ? 1 : 0} 
                            onClick={(e) => {
                            e.preventDefault();
                            mint(mintAmount);
                            props.getData();}}>
                            <span className="mint_button_text"> {claimingNft ? "MINTING.." : "MINT"}</span>
                          </button>
                        </div>
                      )}
                      <span className="mint_ghorse_list">
                        <div className={mintAmount >= 1 ? "ghorse_list_itemFilled" : "ghorse_list_item" }><img className={mintAmount >= 1 ? "mint_ghorse_head" : "mint_ghorse_head-none"} alt="horse head" src={GHORSEHead}/></div>
                        <div className={mintAmount >= 2 ? "ghorse_list_itemFilled" : "ghorse_list_item" }><img className={mintAmount >= 2 ? "mint_ghorse_head" : "mint_ghorse_head-none"} alt="horse head" src={GHORSEHead}/></div>
                        <div className={mintAmount >= 3 ? "ghorse_list_itemFilled" : "ghorse_list_item" }><img className={mintAmount >= 3 ? "mint_ghorse_head" : "mint_ghorse_head-none"} alt="horse head" src={GHORSEHead}/></div>
                        <div className={mintAmount >= 4 ? "ghorse_list_itemFilled" : "ghorse_list_item" }><img className={mintAmount >= 4 ? "mint_ghorse_head" : "mint_ghorse_head-none"} alt="horse head" src={GHORSEHead}/></div>
                        <div className={mintAmount >= 5 ? "ghorse_list_itemFilled" : "ghorse_list_item" }><img className={mintAmount >= 5 ? "mint_ghorse_head" : "mint_ghorse_head-none"} alt="horse head" src={GHORSEHead}/></div>
                        <div className={mintAmount >= 6 ? "ghorse_list_itemFilled" : "ghorse_list_item" }><img className={mintAmount >= 6 ? "mint_ghorse_head" : "mint_ghorse_head-none"} alt="horse head" src={GHORSEHead}/></div>
                        <div className={mintAmount >= 7 ? "ghorse_list_itemFilled" : "ghorse_list_item" }><img className={mintAmount >= 7 ? "mint_ghorse_head" : "mint_ghorse_head-none"} alt="horse head" src={GHORSEHead}/></div>
                        <div className={mintAmount >= 8 ? "ghorse_list_itemFilled" : "ghorse_list_item" }><img className={mintAmount >= 8 ? "mint_ghorse_head" : "mint_ghorse_head-none"} alt="horse head" src={GHORSEHead}/></div>
                        <div className={mintAmount >= 9 ? "ghorse_list_itemFilled" : "ghorse_list_item" }><img className={mintAmount >= 9 ? "mint_ghorse_head" : "mint_ghorse_head-none"} alt="horse head" src={GHORSEHead}/></div>
                        <div className={mintAmount >= 10 ? "ghorse_list_itemFilled" : "ghorse_list_item" }><img className={mintAmount >= 10 ? "mint_ghorse_head" : "mint_ghorse_head-none"} alt="horse head" src={GHORSEHead}/></div>
                      </span>
                      <p> {feedback} </p>
                      <p style={{marginTop: 30}}>Your balance : {props.balance}</p>   
                    </div> 
                  </>
                )} 
              </div> 
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mint;
