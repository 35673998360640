import '../style/home.scss';
import Gif_horsemen from '../assets/Gif_horsemen.gif';


function NotFound() {
  return (
    <div className="not_found">
        <h1>Error 404</h1>
        <h2>Page not found</h2>
        <img className="img_gif" src={Gif_horsemen} alt="gif horsemen"/>
    </div>
  );
}

export default NotFound;