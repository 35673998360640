// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};
//prend en paramétre un account et affiche les data 
export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let TOTAL_SUPPLY = await store
        .getState()
        .blockchain.smartContract.methods.TOTAL_SUPPLY()
        .call();
      let airdropNumber = await store
        .getState()
        .blockchain.smartContract.methods.airdropNumber()
        .call();
      let minted = await store
        .getState()
        .blockchain.smartContract.methods.minted()
        .call();
      let currentSupply = parseInt(minted) + parseInt(airdropNumber)
      let walletOfOwner = await store
        .getState()
        .blockchain.smartContract.methods.walletOfOwner(await store
          .getState()
          .blockchain.account)
        .call()

      let tokenURI = await Promise.all(walletOfOwner.map(async (token) => {
        let URI = await store
        .getState()
        .blockchain.smartContract.methods.tokenURI(token).call()
        let slicedURI = URI.slice(67, URI.length - 5);
        return slicedURI
      }))
      console.log(tokenURI)

     

      dispatch(
        fetchDataSuccess({ //Enregistre le total supply du contrat récupéré depuis le store
          TOTAL_SUPPLY,
          airdropNumber,
          minted,
          currentSupply,
          tokenURI
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
