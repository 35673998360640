import Razmo from '../../assets/Razmo.webp';
import Darwiin from '../../assets/Darwiin.webp';
import Gumball from '../../assets/Gumball.webp';
import Oguzhan from '../../assets/Dony.webp';

function Team() {
  return (
    <div className="Team">
        <div className="section_team">
        <h1 className="team_title">TEAM</h1>
          <div className="team_liste">
            <div className="team_line" data-aos="fade-up">
              <div className="team_card">
                  <img className="team_img" alt="img team" src={Gumball}/>
                  <div className="team_name">
                      <h2>Gumball</h2>
                  </div>
              </div>
              <div className="team_card">
                    <img className="team_img" alt="img team" src={Darwiin}/>
                  <div className="team_name">
                      <h2>Darwiin</h2>
                  </div>
              </div>
            </div>
            <div className="team_line" data-aos="fade-up">
              <div className="team_card">
                  <img className="team_img" alt="img team" src={Oguzhan}/>
                  <div className="team_name">
                      <h2>Dony</h2>
                  </div>
              </div>
              <div className="team_card">
                  <img className="team_img" alt="img team" src={Razmo}/>
                  <div className="team_name">
                      <h2>Razmo</h2>
                  </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default Team;
