import '../style/TermsConditions.scss';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';


function TermsConditions() {
  return (
    <div className="terms_conditions">
        <div className="terms_content">
            <h1>TERMS</h1>
            <p>This website is operated by GentleHorsemen. Throughout the site, the term « GentleHorsemen », « we », « us » or « our » as the
                context may require, refer to GentleHorsemen. By visiting our site and/or purchasing something from us, you engage in our
                Service and agree to be bound by the following terms and conditions.</p>

            <p>We reserve the right to update, change or replace any part of these Terms of services without prior written notice at any
                time, and it is your responsibility to periodically review these Terms of Uses to stay informed of updates. Any changes to
                the Terms will be in effect as of the « last updated » referenced on the site.</p>

            <p>Your continued use of this site after the last updated dates will constitute your acceptance of agreement.</p>

            <p>By visiting the website of our products merchants, in particular www.opensea.io, you also engage and agree to be
                bound by their terms and conditions.</p>

            <p>This website is for informational purposes and serves as a binding contract for purchasers of GentleHorsemen NFTs.</p>

            <p>GentleHorsemen is a generative collection of digital artwork (NFTs) housed and run on the Avalanche Network. Users and
                Collector of these NFTs are solely responsible for the safety and the management of their own private assets, which
                include but are not limited to Avalanche Wallets, validating all transactions and contracts generated by this website
                prior to and after purchases.</p>

            <p>Users certify through purchase that they understand that, as the GentleHorsemen smart contract runs on the Avalanche network
                and is bound by their system and terms, there is no ability to undo, reverse, or restore any transactions.</p>

            <p>Any connected services included this website are provided “as is” and “as available” without any warranty of any kind.
                Use of this website constitutes your agreement that you are accepting sole responsibility for any and all transactions
                involving GentleHorsemen digital collectibles.</p>

            <p>By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or
                province of residence.</p>

            <p>You may not use our products and/or services for any illegal or unauthorized purpose nor may you, in the use of
                the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</p>
            <h1>OWNERSHIP</h1>
            <p>When you purchase your GentleHorsemen NFT, You Own the NFT : the ownership is transferred to you on the Avalanche blockchain
                for that individual piece of Art combination of traits and number. Ownership of the NFT is ruled by the smart contract
                and the Avalanche Network terms. We, GentleHorsemen, have no ability to alter, freeze, seize or modify the ownership of any
                GentleHorsemen NFT.</p>
            <h1>PERSONAL USAGE</h1>
            <p>Subject to your continued acceptance with these terms, GentleHorsemen allows you a worldwide, royalty-free licence to use, copy and
                display the purchased Art, along with any derivative artwork or extensions that you could create or use, limited by the
                following purposes : (i) for your own personal, non commercial use; (ii) as part of a marketplace that allows the purchase
                and sales of you GentleHorsemen / NFT, as long as the marketplace cryptographically verifies that you are the owner, to ensure
                that only the actual owner have the right to display the Art; or (iii) as part of a third party website or application that
                permits the inclusion, involvement or participation of your GentleHorsemen, provided that the website or the application
                cryptographically verifies each GentleHorsemen owner’s right to display the Art for their GentleHorsemen, to ensure that only
                the actual owner can display the Art, and provided that the Art is no longer visible once the actual owner of the
                GentleHorsemen leave the website or application.</p>
            <h1>COMMERCIAL USAGE</h1>
            <p>Subject to actual owner of GentleHorsemen NFTs continued compliance with these Terms, GentleHorsemen grant you an unlimited,
                worldwide licence to use, copy and display the purchased Art for the purpose of creating derivative works based upon
                the Art (« COMMERCIAL USAGE »). The only exception being not to carry out 3D modeling (sale of figurines) because we
                reserve it for the physical part of our drop. Examples of specified Commercial Use would be the use of the Art to produce 
                d sell physical merchandise products (T-Shirt, Posters, etc.) displaying copie of the purchased Art. Please be aware that
                nothing in this section will be deemed to restrict you from (i) owning or operating a marketplace that permits the use and
                sale of GentleHorsemen generally, provided that the marketplace cryptographically verifies each GentleHorsemen owner’s right to
                display the Art of their GentleHorsemen to ensure that only the actual owner can display the Art; (ii) owning or operating a
                third party website or application that permits the inclusion, involvement, or participation of GentleHorsemen generally, provided
                that the third party website or application cryptographically verifies each GentleHorsemen owner’s rights to display the Art for their
                GentleHorsemen e to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the
                owner of the Purchased GentleHorsemen leaves the website/application; or (iii) earning revenue from any of the foregoing.</p>

                <p>Further, GentleHorsemen reserves the right to use ANY character, including : print or digital advertising, or any purely creative media
                    (including short film, anime, etc.) in support of the GentleHorsemen community and message.</p>

                <p>However, you cannot use the artwork in connection with images of hatred, violence or any other inappropriate behavior.
                    The License granted in above only applies to the extent that you continue to own the relevant NFT. If at any time you
                    trade, donate, giveaway, transfer or otherwise dispose of your NFT for any reason, the license granted above will
                    immediately expire, without notice, and you will have no further right in or to the artwork of this NFT.</p>
        </div>
        <Footer/>
    </div>
  );
}

export default TermsConditions;