import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Footer from '../components/Footer';
import Profile from '../components/MyGHORSE/Profile';
import '../style/myghorse.scss';

function MyGHORSE(props) {


  return ( 
    <div className="MyGHORSE">
      <Profile account={props.account} blockchain={props.blockchain} />
      <Footer/>
    </div>
  );
}

export default MyGHORSE;
