import { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Items from '../Items';

import GridOnIcon from '@mui/icons-material/GridOn';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import { useSelector } from 'react-redux';
import GCASH from '../../assets/gcash.png';
import GWALLET from '../../assets/gcash_wallet.png';

export default function Content() {

  const [item, setItem] = useState(true)
  const [unStakedSelected, setunStakedSelected] = useState([])
  const [classUnstaked, setclassUnstaked] = useState([])
  const data = useSelector((state) => state.data);

  const SwitchMainContent = (v) => {
    setItem(v)
}

  const selectedUnstake = (i) => {

    if(unStakedSelected[i] == false){
      unStakedSelected[i] = true;
      classUnstaked[i] = "ItemsSelected";

      console.log(unStakedSelected)
      setunStakedSelected(unStakedSelected);
      setclassUnstaked(classUnstaked);

    }
    else{
      unStakedSelected[i] = false;
      classUnstaked[i] = "Items";

      console.log(unStakedSelected)
      setunStakedSelected(unStakedSelected);
      setclassUnstaked(classUnstaked);    }
  }

  const selectAll = () => {

    unStakedSelected.map((select) => {
      select = !select
      return select
    })
    classUnstaked.map((select) => {
      select = 'ItemsSelected'
    })
    setunStakedSelected(unStakedSelected);
    setclassUnstaked(classUnstaked)
    console.log(unStakedSelected)
  }

  useEffect(()=>{
    data.tokenURI.map((m) => {
      unStakedSelected.push(false)
      classUnstaked.push("Items")
    })

    setunStakedSelected(unStakedSelected)
    setclassUnstaked(classUnstaked)

    console.log(unStakedSelected)
    
  }, [data.loading])

 
   return (
    <div className="MrkContent">
      
        <div className="MrkContentHeader">
          <span onClick={() => !item && SwitchMainContent(true)} className={item && "contentFetched"}>
            <MonetizationOnIcon style={{marginRight: '10px'}}/><p>Staked - 0</p>
          </span>
          <span onClick={() => item && SwitchMainContent(false)}  className={!item && "contentFetched"}>
          {data.loading == false ? <><DarkModeIcon style={{marginRight: '10px'}}/><p>{`Unstaked - ${data.tokenURI.length}`}</p> </>
          : <><DarkModeIcon style={{marginRight: '10px'}}/><p>Unstaked - 0</p> </>}
          </span>
        </div>
        <div className="MrkContentItems">
          { item ? 
          <div style={{width:'100%', padding: '0 30px', boxSizing: "border-box", display: "flex"}}>

            <div className="MrkContentItemsCnt" style={{display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: "center"}}>
              <img alt="market banner" style={{width: "20%"}} src={GWALLET} /> 
              <h2 style={{marginTop: 10}}>No GHORSE stacked</h2>
            </div>
            <div className="MrkContentItemsCnt2" >
              <div style={{margin: "30px 0"}}>
                <button className="MrkButton" style={{width: "40%", marginBottom: 40}}>
                  <span className="mint_button_text">Select all <CheckIcon style={{fontSize: '15px', margin: "0 0 -3px 3px"}}/></span>
                </button>   
                <button className="MrkButton">
                  <span className="mint_button_text">Claim $GCASH</span> <img alt="market banner" style={{width: 17, margin: "0 0 -3px 3px"}} src={GCASH} /> 
                </button> 
                <button className="MrkButton">
                  <span className="mint_button_text">Claim $GCASH and unstake</span>
                </button>          
                <h4>Unclaimed: 0.00 $GCASH</h4>
              </div>
              <h4 style={{textAlign: "end", margin: "0 -20px 30px 0"}} >Balance: 0.00 $GCASH</h4>
            </div>
          </div>
        :
        //RECENT SALES PART --------------------
        <div style={{width:'100%', padding: '30px', boxSizing: "border-box", display: "flex"}}>

        <div className="MrkContentItemsCnt">
          <Grid container spacing={2} columns={{xs: 12}} className="MrkContentItemsCnt">
            { data.loading == false ? data.tokenURI.map( (nft,i) => (
              <Grid  onClick={() => { SwitchMainContent(false); selectedUnstake(i);}} item  xs={3} key={parseInt(nft)}>
                  <Items name={`#${parseInt(nft)}`} image={parseInt(data.tokenURI[i])}/>
              </Grid>         
              ))
            :
            <></>
            }
          </Grid>
        </div>
        <div className="MrkContentItemsCnt2">
          <div>
          <button className="MrkButton" style={{width: "40%", marginBottom: 40}}>
          <span className="mint_button_text">Select all <CheckIcon style={{fontSize: '15px', margin: "0 0 -3px 3px"}}/></span>
          </button>   
          <button className="MrkButton">
            <span className="mint_button_text">Stake</span>
          </button>      
          </div>    
          <h4 style={{textAlign: "end", margin: "0 -20px 0 0"}} >Balance: 0.00 $GCASH</h4>
        </div>
      </div>
        }
     </div>

    </div>
  );
}

