// constants
import Web3EthContract from "web3-eth-contract"; //This allows you to interact with smart contracts as if they were JavaScript objects.
import Web3 from "web3";
// log
import { fetchData } from "../data/dataActions";
import { config } from "../config";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

//connect() va détecter si on a metamask, si on est sur le bon réseau, et va charger toutes les données provenant du contract pour les stocker dans le store
export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
  
    
    const CONFIG = await config;
    
    //check si metamask est installé, si c'est le cas il check si le client est sur le bon network, sinon il demande de l'installer
    const { ethereum } = window; //window représ une fenêtre contenant un document DOM, chaque onglet contient son propre objet window
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (metamaskIsInstalled) {
      Web3EthContract.setProvider(ethereum);  //on set la fenêtre qui fournira notre contract
      let web3 = new Web3(ethereum);
      try {
        const accounts = await ethereum.request({ //récup l'ensemble des adresses sur metamask
          method: "eth_requestAccounts",
        });
        const networkId = await ethereum.request({
          method: "net_version",
        });
        const chainId = await ethereum.request({
          method: "eth_chainId",
        });
         console.log(chainId)

        //si on est sur le bon réseau, on stock dans SmartContractObj l'abi et l'adresse du contrat
        if (chainId.toLowerCase() == CONFIG.NETWORK.CHAINID.toLowerCase()) { 


          //on récupère les données importantes de notre dossier config en json 
          const abiResponse = await fetch("/config/abi.json", { //on recup l'abi avec fetch. "headers" est nécessaire à l'importation
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });
          const abi = await abiResponse.json(); //une fois importé on remet l'abi sous format json
          const SmartContractObj = new Web3EthContract(
            abi,
            CONFIG.CONTRACT_ADDRESS
          );
  
          //on stock tout ça dans notre store
          dispatch(
            connectSuccess({
              account: accounts[0],
              balance: accounts[0],
              smartContract: SmartContractObj,
              web3: web3,
            })
          );
          // Add listeners start
          ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });

          ethereum.on("chainChanged", () => {
            window.location.reload();
          });
          
         
          //If not in the right chainID => sending a request to change to it.
         } else {
           
           dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
           ethereum.on("chainChanged", () => {
             window.location.reload();
           });
         }

      } catch (err) {
        dispatch(connectFailed("To connect to the website, please check your MetaMask plugin."));
      }
    } else {
      dispatch(connectFailed("Please ensure that you have Metamask extension installed."));
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};