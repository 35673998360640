import '../style/tokenomics.scss';
import Footer from '../components/Footer';
import Price from '../assets/reward_price.png';
import Reward from '../assets/reward_reward.png';
import Market from '../assets/reward_market.png';
import Target from '../assets/target.png';
import KalaoLogo from '../assets/KalaoLogo.png';
import GCASH from '../assets/gcash.png';
import NamePrototype from '../assets/NamePrototype.png';

export default function Tokenomics() {
  return ( 
    <div className="Tokenomics">
    <div className="Tokenomics_content">
        <h1>WHITEPAPER</h1>
        <h3 style={{color: "#bcbcbc", marginTop: -5}} >What do I earn by owning a GHORSE?</h3>


        <div className="Tokenomics_subcontent_mint">
          <h4 style={{textAlign: "start", marginTop: 50}}>MINTING</h4>
          <p  style={{ marginTop: -10}}>Price : 2.5 to 2 AVAX in a decreasing way for the first 3887 horsemen minted, then the price stabilizes at 2 AVAX for the following ones.</p>
        </div>

        <div className="Tokenomics_subcontent">
          <h4 style={{textAlign: "start", marginTop: 10}}>POST-MINT REWARD</h4>
          <p  style={{ marginTop: -10}}>Holders of the first 3887 NFT mint can collect a post mint reward on the website. This reward varies depending on the position of your TOKEN and the number of Horsemen that have been minted. The earlier you mint, the greater the reward. It can be up to 0.75 AVAX. In case 7777 Gentle Horsemen have been minted:<br/>
          - If you are the first to mint, the prize will be 2.5 AVAX and you will get back 0.75 AVAX of reward.<br/>
          - If you mint the 1656th Gentle Horsemen, the price will be 2.28 AVAX and you will get 0.4 AVAX reward.<br/>
          - The final price after reward recovery will always be less than 2 AVAX.</p>
          <div className='Tokenomics_img' style={{width: "100%"}}>
            <img alt="logo horsemenclub" src={Price} />
            <img alt="logo horsemenclub" src={Reward} />
          </div>      
        </div>

        <div style={{backgroundColor: "#0f1013"}}>
          <div className="Tokenomics_subcontent">
            <div style={{display: "flex",  marginTop: 50}}>
              <h4 style={{textAlign: "start"}}>STAKING TO EARN $GCASH</h4>
              <img  style={{width: "30px", height: "30px", margin: "15px 5px 0 7px"}} alt="logo horsemenclub" src={GCASH} />
            </div>
            <p style={{ marginTop: -10}}>You can stake your Gentlehorsemen in the Staking tab of the website. Each GHORSE generates 10,000 $GCASH / day.<br/><br/>
                The maximum $GCASH supply is 1,000,000,000 $GCASH:<br/>
                When supply reaches 500,000,000 $GCASH earned for staking, the staking “faucet” turns off.<br/>
                The developers will receive 100,000,000 $GCASH.<br/>
                Community Rewards will be allocated 400,000,000 $GCASH.</p>
          </div>
        </div>


        <div>
          <div className="Tokenomics_subcontent">
            <h4 style={{textAlign: "start", marginTop: 50}}>$KLO STAKED REWARD</h4>
            <p style={{ marginTop: -10}}>Every 10% of the mint reached, we will stake $10K from the $KLO pool for 1 year, then give the full amount to the holders.</p>
            <div style={{width: "100%", display: "flex"}}>
              <img className='Tokenomics_kalao' style={{marginTop: 20}} alt="logo horsemenclub" src={KalaoLogo} />
            </div>
          </div>
        </div>
        
  


        <div style={{backgroundColor: "#0f1013"}}>
          <div className="Tokenomics_subcontent">
            <h4 style={{textAlign: "start", marginTop: 50}}>MARKET REWARD</h4>
            <p style={{ marginTop: -10}}>When selling GentleHorsemen on the marketplace. The marketplace will collect 6% royalties and distribute them as follows:<br/>
            - 3% for the original Gentle Horseman minter<br/>
            - 2% distributed to the community (for all Gentle Horsemen owners)<br/>
            - 1% for the smart contract<br/>
            In short, the community gets 83.33% of the royalties collected by the Marketplace.</p>
            <div style={{width: "100%", display: "flex", justifyContent:"center"}}>
              <img className='Tokenomics_marketImg' style={{marginTop: 20}} alt="logo horsemenclub" src={Market} />
            </div>
          </div>
        </div>

        <div>
          <div className="Tokenomics_subcontent">
            <h4 style={{textAlign: "start", marginTop: 50}}>TOP HOLDERS REWARD</h4>
            <p style={{ marginTop: -10}}>At 25% mint, 400 AVAX distributed to 9 holders with the most Ghorse :</p>
              <ul style={{color: "white"}}>
                <li> 1st: 100 AVAX</li>
                <li> 2nd to 5th: 50 AVAX</li>
                <li> 6th to 9th: 25 AVAX!</li>
              </ul>
          </div>
        </div>

        <div style={{backgroundColor: "#0f1013"}}>
          <div className="Tokenomics_subcontent">
            <div style={{display: "flex",  marginTop: 50}}>
              <h4 style={{textAlign: "start"}}>BOUNTY REWARD</h4>
              <img  style={{width: "30px", height: "30px", margin: "12px 5px 0 7px"}} alt="logo horsemenclub" src={Target} />
            </div>
            <p style={{ marginTop: -10}}>At 75% mint, 70 GentleHorsemen will be wanted by the authorities.<br/>
            The owners of these GHORSE will be able to exchange them for $1000 worth AVAX at our community smart contract.</p>
          </div>
        </div>

          <div className="Tokenomics_subcontent_mint">
            <h4 style={{textAlign: "start", marginTop: 50}}>NAME YOUR ROBOT</h4>
            <p style={{ marginTop: -10}}>Owners who own GentleHorsemen with the Robot Steampunk skin will be able to give them a custom name on 30/01/2022.</p>
            <img className='Tokenomics_steampunk' style={{marginTop: 20}} alt="logo horsemenclub" src={NamePrototype} />
          </div>
    </div>
    <Footer/>
</div>
  );
}
