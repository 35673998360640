
function Roadmap() {
  return (
    <div className="Roadmap">
      <div className="section_roadmap">
        <div className="section_roadmap_aos">

            <h1 className="roadmap_title">ROADMAP</h1>

            <div className="roadmap_line_sold" >
              <div className="roadmap_card_sold"  data-aos="fade-up">
                <div className="roadmap_title_sold">
                  <h1>25<span>%</span></h1>
                  <span>SOLD</span>
                </div>
                <p className="roadmap_text_sold">
                The Club thanks you for collaborating with a large number of GentleHorsemen. 400 AVAX will be distributed as
                follows to 9 holders with the most GHORSE :<br />
                1st: <span>100 AVAX</span><br />
                2nd to 5th: <span>50 AVAX</span><br />
                6th to 9th: <span>25 AVAX</span><br />
                </p>
              </div>
              <div className="roadmap_card_sold"  data-aos="fade-up">
                <div className="roadmap_title_sold">
                    <h1>50<span>%</span></h1>
                    <span>SOLD</span>
                  </div>
                <p className="roadmap_text_sold">
                  The mint price drops to <span>2 AVAX</span> (Mint Reward disabled)
                </p>
              </div>
              <div className="roadmap_card_sold"  data-aos="fade-up">
                <div className="roadmap_title_sold">
                    <h1>75<span>%</span></h1>
                    <span>SOLD</span>
                  </div>
                <p className="roadmap_text_sold">
                70 GentleHorsemen out of 7,777 will be wanted by the authorities. The owners of these GentleHorsemen will be
                able to exchange them for <span>$1000</span> at our community smart contract.
                </p>
              </div>
              <div className="roadmap_card_sold"  data-aos="fade-up">
                <div className="roadmap_title_sold">
                    <h1>100<span>%</span></h1>
                    <span>SOLD</span>
                  </div>
                <p className="roadmap_text_sold">
                GHORSE Airdrop. Welcome to all, ladies and gentlemen.  Let's celebrate the begining of the most stylish
                secret organization around a good glass of scotch! 
                </p>
              </div>
            </div>

        </div>
      </div>
    </div>
  );
}

export default Roadmap;
