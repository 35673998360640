import '../style/footer.scss';
import NewLogo from '../assets/NewLogo.png';
import Twitter from '../assets/Logo_Twitter.png';
import Discord from '../assets/Logo_Discord.png';
import Telegram from '../assets/Logo_Telegram.png';
/*import Opensea from '../assets/Opensea.webp';*/

function Footer() {
  return (
    <div className="Footer">
      <div className="section_footer">
        <div className="footer_content">

          <div className="footer_left">
            <div className="footer_infos">
                  <img alt="logo horsemen" src={NewLogo}/>
            </div>
            <div className="footer_copyright">
              <p>©2021 GentleHorsemen. All rights reserved.</p>
            </div>
          </div>

          <div className="footer_right">
            <div className="footer_nav">
              <a href="/">Home</a>
              <a href="/terms">Terms & Conditions</a>
            </div>
            <div className="social_nav">
          <div className="link">
            <a href="https://twitter.com/GentleHorsemen" target="_blank" rel="noreferrer">
            <img alt="logo Twitter" src={Twitter}/>  
            </a>
          </div>
          <div className="link">
            <a href="https://discord.gg/99ekYG7Cqk" target="_blank" rel="noreferrer">
            <img alt="logo Discord" src={Discord}/>  
            </a>
          </div>
          <div className="link">
            <a href="https://t.me/gentlehorsemen" target="_blank" rel="noreferrer">
            <img alt="logo Telegram" src={Telegram}/>
            </a>
          </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
