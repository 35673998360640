//résumé: On fait une CONNECTION_REQUEST, si la connection réussit on récupère dans le store les info entrées. Sinon on récupère dans le store l'erreur 
const initialState = {
  countdownVisible: null,
  popoverVisible: false,
  dialog: false,
  dialogID: null,
  buySell: false,
  buySellID: null,
  isFullscreen: false,
  errorDisplay: false,
  imgID: 0,
  URI: [],

  };

const conditionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "COUNTDOWN_FETCH":
      return {
        ...state,
        countdownVisible: true,
      };
      case "COUNTDOWN_HIDE":
      return {
        ...state,
        countdownVisible: false,
      };
      case "POPOVER_FETCH":
        return {
          ...state,
          popoverVisible: true,
        };
        case "POPOVER_HIDE":
        return {
          ...state,
          popoverVisible: false,
        };
        case "DIALOG_CLOSE":
          return {
            ...state,
            dialog: false,
            dialogID: null
          };
        case "DIALOG_OPEN":
          return {
            ...state,
            dialog: true,
            dialogID: action.payload,
          };
          case "BUYSELL_CLOSE":
            return {
              ...state,
              buySell: false,
              buySellID: null
            };
          case "BUYSELL_OPEN":
            return {
              ...state,
              buySell: true,
              buySellID: action.payload,
            };
          case "FULLSCREEN_SWAP":
            return {
              ...state,
              isFullscreen: !state.isFullscreen,
              imgID: action.payload,

            };
          case "ERRORMSG_SWAP":
            return {
              ...state,
              errorDisplay: !state.errorDisplay,
            };
            case "UPDATE_URI":
              return {
                ...state,
                URI: action.payload,
              };
    default:
      return state;
  }
};

export default conditionReducer;
