import '../../style/home.scss';

import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Faq() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="Faq">
      <div className="section_faq">
      <div className="topline"></div>
      <div className="section_faq_aos">
      <h1 className="faq_title">FAQ</h1>
        <div className="faq_liste"  data-aos="fade-up">

          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{fill: "#06ffff"}}/>}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography>
                How many GentleHorsemen can I mint at a time? Is there a limit per wallet?
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              The minting transaction is limited to 10 GentleHorsemen.
              No limit per wallet.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{fill: "#06ffff"}}/>}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
            >
              <Typography>
                When does minting start?
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Pre-sales : 19/01/2022 at 6:00 PM UTC <br/>
               Public Sales : 20/01/2022 at 6:00 PM UTC
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{fill: "#06ffff"}}/>}
              aria-controls="panel8bh-content"
              id="panel8bh-header"
            >
              <Typography>
              What about mint price?
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              The mint price varies from 2.5 to 2 AVAX for the first 3888 GHORSE, then 2 AVAX for the 3889 remaining GHORSE.
              Why do the first 3888 pay more? All the details are on our tokenomics page.
              </Typography>
            </AccordionDetails>
          </Accordion>


        </div>
      </div>
      </div>
    </div>
  );
}